// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-void-magazine-js": () => import("./../../src/pages/a-void-magazine.js" /* webpackChunkName: "component---src-pages-a-void-magazine-js" */),
  "component---src-pages-archive-js": () => import("./../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shop-js": () => import("./../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-templates-about-page-js": () => import("./../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-feed-entry-js": () => import("./../../src/templates/feed-entry.js" /* webpackChunkName: "component---src-templates-feed-entry-js" */),
  "component---src-templates-shop-item-js": () => import("./../../src/templates/shop-item.js" /* webpackChunkName: "component---src-templates-shop-item-js" */)
}

