import React from 'react'
import Helmet from 'react-helmet'
import Navbar from '../components/Navbar'
import Transition from '../components/Transition'
import '../styles/main.scss'
import favicon256 from '../favicons/android-chrome-256x256.png'
import favicon192 from '../favicons/android-chrome-192x192.png'
import favicon144 from '../favicons/android-chrome-144x144.png'


const TemplateWrapper = ({ children, location }) => {
  return <div className="wrapper">
      <Helmet title="Morbid Books" link={[
          { rel: 'icon', type: 'image/png', sizes: "256x256", href: `${favicon256}` },
          { rel: 'icon', type: 'image/png', sizes: "192x192", href: `${favicon192}` },
          { rel: 'icon', type: 'image/png', sizes: "144x144", href: `${favicon144}` }
        ]} />
      <Navbar />
      <div className="container">
        <Transition transitionKey={location.pathname}>{children}</Transition>
      </div>
    </div>
}

export default TemplateWrapper
