import React, { Component } from 'react'
import { Link } from 'gatsby'
import spoon from '../svg/spoon_sub.svg'
import facebook from '../svg/facebook.svg'
import instagram from '../svg/instagram.svg'
import twitter from '../svg/twitter.svg'
import { CSSTransition } from 'react-transition-group'
import Transition from '../components/Transition'

export default class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuVisible: false,
      socialVisible: false
    }
  }
  toggleMenu = () => {
    this.setState({
      menuVisible: !this.state.menuVisible
    })
  }
  hideMenu = () => {
    if (this.state.menuVisible) {
      this.setState({
        menuVisible: false
      })
    }
  }
  showSocial = () => {
    this.setState({
      socialVisible: true
    })
    this.timerHandle = setTimeout(() => {
      this.setState({ socialVisible: false })
    }, 4000)    
  }
  componentWillUnmount() {
    this.timerHandle && clearTimeout(this.timerHandle)
  }
  render() {
    return <nav className="nav">
        <div className="nav__menu nav__menu--default">
          <div className="nav__menu__logo">
            <img src={spoon} alt="spoon" />
          </div>
          <Link className="nav__menu__item link link--nav" activeClassName="link--nav-active" to="/a-void-magazine">
            Home
          </Link>
          <a className="nav__menu__item link link--nav" activeClassName="link--nav-active" href="https://cargorecordsdirect.co.uk/collections/morbid-books" target="_blank" rel="noopener noreferrer">
            Shop
          </a>
          <Link className="nav__menu__item link link--nav" activeClassName="link--nav-active" to="/archive">
            Archive
          </Link>
          <Link className="nav__menu__item link link--nav" activeClassName="link--nav-active" to="/about">
            About
          </Link>
          <a className="nav__menu__item link link--nav" href="https://www.patreon.com/morbidbooks" target="_blank" rel="noopener noreferrer">
            Join
          </a>
          <Transition transitionKey={this.state.socialVisible}>
            {this.state.socialVisible ? <div className="social">
                <a className="link link--nav" href="https://facebook.com/morbidbookslondon" target="_blank" rel="noopener noreferrer">
                  <img src={facebook} className="social__icon" alt="Facebook" />
                </a>
                <a className="link link--nav" href="https://www.instagram.com/morbidbooks1" target="_blank" rel="noopener noreferrer">
                  <img src={instagram} className="social__icon" alt="Instagram" />
                </a>
                <a className="link link--nav" href="https://twitter.com/morbidbooks" target="_blank" rel="noopener noreferrer">
                  <img src={twitter} className="social__icon" alt="Twitter" />
                </a>
              </div> : <label className="nav__menu__item link link--nav" onClick={this.showSocial}>
                Social
              </label>}
          </Transition>
        </div>
        <div className="nav__menu nav__menu--mobile">
          <div className="nav__menu__bar">
            <Link className="nav__menu__item link link--nav" to="/a-void-magazine">
              Morbid Books
            </Link>
            <label className="nav__menu__item" onClick={this.toggleMenu}>
              {this.state.menuVisible ? 'Close' : 'Menu'}
            </label>
          </div>
          <div className="nav__menu__drawer">
            <CSSTransition in={this.state.menuVisible} classNames="slide" timeout={{ enter: 400, exit: 400 }} unmountOnExit>
              <div className="menu-drawer">
                <a className="nav__menu__item link link--nav" onClick={this.toggleMenu} href="https://cargorecordsdirect.co.uk/collections/morbid-books" target="_blank" rel="noopener noreferrer">
                  Shop
                </a>
                <Link className="nav__menu__item link link--nav" to="/archive" onClick={this.toggleMenu}>
                  Archive
                </Link>
                <Link className="nav__menu__item link link--nav" to="/about" onClick={this.toggleMenu}>
                  About
                </Link>
                <a className="nav__menu__item link--nav" href="https://www.patreon.com/morbidbooks" target="_blank" rel="noopener noreferrer">
                  Join
                </a>
                <Transition transitionKey={this.state.socialVisible}>
                  {this.state.socialVisible ? <div className="social">
                      <a className="link link--nav" href="https://facebook.com/morbidbookslondon" target="_blank" rel="noopener noreferrer">
                        <img src={facebook} className="social__icon" alt="Facebook" />
                      </a>
                      <a className="link link--nav" href="https://www.instagram.com/morbidbooks1" target="_blank" rel="noopener noreferrer">
                        <img src={instagram} className="social__icon" alt="Instagram" />
                      </a>
                      <a className="link link--nav" href="https://twitter.com/morbidbooks" target="_blank" rel="noopener noreferrer">
                        <img src={twitter} className="social__icon" alt="Twitter" />
                      </a>
                    </div> : <label className="nav__menu__item link link--nav" onClick={this.showSocial}>
                      Social
                    </label>}
                </Transition>
              </div>
            </CSSTransition>
          </div>
        </div>
      </nav>
  }
}
